import { Alert, Card, ListItemText, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Container, TablePagination } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import Scrollbar from 'src/components/scrollbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  TableHeadCustom,
  TableSkeleton,
  TableEmptyRows,
  emptyRows,
  TableNoData,
  useTable,
} from 'src/components/table';
import { PageLayout } from 'src/layouts/profile/pageLayout';
import { gql, useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import { formatDate } from 'src/utils/formatDate';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import { StatementTableFilterValue } from 'src/types/custom/statement';

const TABLE_HEAD = [
  { id: 'date', label: 'Kayıt Bilgisi', visible: true },
  { id: 'number', label: 'Açıklama / Sevk Adres', visible: true }, 
  { id: 'description', label: 'Ürün', visible: true },
  { id: 'balance', label: 'Miktar', visible: true },
];

const GET_FN_ORDER_TODAY_SHIPPED = gql`
  query GetTodayShippedOrders($id: String!, $startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    getFnOrderTodayShipped(
      id: $id,
      startDate: $startDate,
      endDate: $endDate
    )
  }
`;

interface OrderData {
  document_date: string;
  order_key: string;
  document_number: string;
  shipment_address: string;
  product_name: string;
  product_code: string;
  quantity: number;
  unit_desc: string;
  size: string;
  ordered_at: string;
}

interface Props {
  company: {
    code: string;
  };
  user: any;
}

const startDate = moment().tz('Europe/Istanbul').startOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
const endDate = moment().tz('Europe/Istanbul').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');

interface OrderFilters {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
}

export default function TodayShippedView({ company }: Props) {
  const table = useTable();
  const [searchParams, setSearchParams] = useSearchParams();
  const [statementsEmpty, setStatementsEmpty] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [parsedOrders, setParsedOrders] = useState<OrderData[]>([]);
  const [error, setError] = useState<string | null>(null);

  const parseUrlDate = (dateStr: string | null) => {
    if (!dateStr) return null;
    const parsedDate = moment(dateStr);
    return parsedDate.isValid() ? parsedDate.toDate() : null;
  };

  const defaultFilters: OrderFilters = {
    name: searchParams.get('name') || '',
    startDate: parseUrlDate(searchParams.get('startDate')),
    endDate: parseUrlDate(searchParams.get('endDate')),
  };

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    setFilters({
      name: searchParams.get('name') || '',
      startDate: parseUrlDate(searchParams.get('startDate')),
      endDate: parseUrlDate(searchParams.get('endDate')),
    });
  }, [searchParams]);

  const handleFilters = useCallback(
    (name: string, value: StatementTableFilterValue) => {
      table.onResetPage();
      setPage(0);
      setRowsPerPage(20);

      setFilters((prevState) => {
        const newFilters = { ...prevState, [name]: value };
        
        const params = new URLSearchParams(searchParams);
        if (value) {
          if (value instanceof Date && moment(value).isValid()) {
            params.set(name, moment(value).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
          } else if (typeof value === 'string') {
            params.set(name, value);
          }
        } else {
          params.delete(name);
        }
        setSearchParams(params);

        return newFilters;
      });
    },
    [table, searchParams, setSearchParams]
  );

  const handleResetFilters = useCallback(() => {
    setPage(0);
    setRowsPerPage(20);
    setFilters({
      name: '',
      startDate: null,
      endDate: null,
    });
    setSearchParams({});
  }, [table, searchParams, setSearchParams]);

  const denseHeight = table.dense ? 56 : 76;
  const canReset = !isEqual(null, company);
  const notFound = statementsEmpty || (canReset && statementsEmpty);

  const { data, loading } = useQuery(GET_FN_ORDER_TODAY_SHIPPED, {
    variables: { 
      id: company?.code,
      startDate: filters.startDate || startDate,
      endDate: filters.endDate || endDate,
    },
    skip: !company?.code,
    onError: (error) => {
      setError('Veriler çekilirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.');
      console.error('Query error:', error);
    }
  });
  useEffect(() => {
    if (data?.getFnOrderTodayShipped) {
      try {
        const parsed = JSON.parse(data.getFnOrderTodayShipped || '[]') as OrderData[];
        const sortedParsed = parsed.sort((a, b) => {
          const dateA = new Date(a.document_date).getTime();
          const dateB = new Date(b.document_date).getTime();
          
          if (isNaN(dateA) && isNaN(dateB)) return 0;
          if (isNaN(dateA)) return 1;
          if (isNaN(dateB)) return -1;
          
          return dateB - dateA;
        });
        setParsedOrders(sortedParsed);
        setStatementsEmpty(!parsed.length);
      } catch (error) {
        console.error('JSON parse error:', error);
        setParsedOrders([]);
        setStatementsEmpty(true);
      }
    }
  }, [data]);

  const paginatedOrders = parsedOrders.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
console.log(parsedOrders)
  const renderTableRow = (row: OrderData, index: number) => (
    <TableRow hover tabIndex={-1} key={index} sx={{ height: denseHeight }}>
      <TableCell>
        <ListItemText
          primary={formatDate(row.document_date)}
          secondary={row.order_key}
        />
      </TableCell>
      <TableCell>
        <ListItemText 
          primary={row.document_number || ''} 
          secondary={row.shipment_address.slice(0, 50) || ''} 
        />
      </TableCell>
      <TableCell>
        <ListItemText 
          primary={row.product_name || ''} 
          secondary={row.product_code || ''} 
        />
      </TableCell>
      <TableCell>
        <ListItemText
          primary={
            <>
              {row.quantity || 0}{' '}
              <Typography variant="caption" component="span">
                {row.unit_desc || ''}
              </Typography>
            </>
          }
          secondary={row.size}
        />
      </TableCell>
    </TableRow>
  );

  return (
    <PageLayout header="Sevk Edilen Siparişler">
      <Container maxWidth="xl">
        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <DatePicker
            label="Başlangıç Tarihi"
            value={filters?.startDate}
            maxDate={filters?.endDate || undefined}
            onChange={(newValue: Date | null) => {
              if (newValue && moment(newValue).isValid()) {
                if (filters?.endDate && moment(newValue).isAfter(filters.endDate)) {
                  return;
                }
                handleFilters('startDate', newValue);
              } else {
                handleFilters('startDate', null);
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                error: false,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    const target = e.target as HTMLInputElement;
                    const date = moment(target.value, 'DD.MM.YYYY');
                    if (date.isValid()) {
                      handleFilters('startDate', date.toDate());
                    }
                  }
                }
              },
            }}
          />

          <DatePicker
            label="Bitiş Tarihi"
            value={filters?.endDate}
            minDate={filters?.startDate || undefined}
            onChange={(newValue: Date | null) => {
              if (newValue && moment(newValue).isValid()) {
                if (filters?.startDate && moment(newValue).isBefore(filters.startDate)) {
                  return;
                }
                handleFilters('endDate', newValue);
              } else {
                handleFilters('endDate', null);
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                error: false,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    const target = e.target as HTMLInputElement;
                    const date = moment(target.value, 'DD.MM.YYYY');
                    if (date.isValid()) {
                      handleFilters('endDate', date.toDate());
                    }
                  }
                }
              },
            }}
          />
        </Stack>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={parsedOrders.length}
                  visible={TABLE_HEAD}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {loading ? (
                    [...Array(rowsPerPage)].map((_, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {paginatedOrders.map((row, index) => renderTableRow(row, index))}
                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, parsedOrders.length)}
                  />
                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={parsedOrders.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </PageLayout>
  );
}
